import React from "react";
import "./caseSites.css";

import { ProjectType, mediaPath } from "../home/Home";

export function CaseSites({
  className,
  project,
}: {
  className: string;
  project: ProjectType;
}) {
  return (
    <div className={`${className} caseSites case-window`}>
      <div className="container">
        <div className="title">
          <span className="pink-text">КЕЙС</span>
          <br />
          {project.title}
        </div>
        <div className="logo">
          <img src={mediaPath("logo.png")} alt="" />
        </div>
      </div>
      <div className="main">
        <div className="desk-case">
          <div className="img-parent">
            <img src={project.image} className="laptop" alt="" />
            <img src={project.image} className="laptop" alt="" />
            <div className="bg-blur-parent">
              <div className="blur"></div>
            </div>
          </div>

          <img src="/pk/bgDesign.png" className="bg-text" alt="" />
        </div>
        <div className="description-parent">
          <div className="line"></div>
          <div className="descriptions">
            <div className="mini-title-box">
              <div className="mini-title">
                <div className="circle"></div>
                Цель
              </div>
              <div className="description">{project.purpose}</div>
            </div>
            <div className="mini-title-box">
              <div className="mini-title">
                <div className="circle"></div>
                Решение
              </div>
              <div className="description">{project.solution}</div>
            </div>
            <div className="mini-title-box">
              <div className="mini-title">
                <div className="circle"></div>
                Итоги
              </div>
              <div className="description">{project.result}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
