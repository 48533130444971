import React, { useEffect, useState } from "react";

import smallWoman from "./img/smallWoman.png";
import "./home.css";
import { Header } from "./components/Header";
import { Cases, MobileCases } from "./components/Cases";
import { Contact } from "./components/Contact";
import { Services } from "./components/Services";
import { SaveVisit } from "./components/SaveVisit";
import { CaseBot } from "../case_page/CaseBot";
import { CaseSites } from "../case_page/CaseSites";
import { moveBack, moveForward } from "./components/CarouselHelpers";
import { CaseBase } from "../case_page/CaseBase";
import { CaseSticker } from "../case_page/CaseSticker";
import { Order } from "./components/Order";
import "./mobCases.css";

export const isMobile = window.innerWidth < 600;

// Check if the device is a tablet
export const isTablet = window.innerWidth < 1000;

export const mediaPath = (name: string) =>
  `/${isMobile ? "mobile" : isTablet ? "tablet" : "pk"}/${name}`;

function Introduction({
  showOrder,
  setShowOrder,
}: {
  showOrder: boolean;
  setShowOrder: any;
}) {
  return (
    <div className="introduction">
      {showOrder && <Order onClose={() => setShowOrder(false)} />}
      <div className="bg-black-shadow"></div>
      <div className="bg-pink-shadow"></div>
      <div className="text-part">
        <div className="main-text title">
          ВАШ ПУТЬ В ЦИФРОВУЮ ЭРУ НАЧИНАЕТСЯ
          <span className="pink-text">&nbsp;ЗДЕСЬ!</span>
        </div>
        <div className="separating-line"></div>
        <div className="second-text">
          От дизайна сайтов и мобильных приложений до интегрированных
          маркетинговых кампаний и аналитики - мы обеспечиваем полный спектр
          цифровых услуг
        </div>
        <button onClick={() => setShowOrder(true)} className="order-btn">
          <p>ЗАКАЗАТЬ</p>
        </button>
      </div>
      <div className="woman">
        <img src={mediaPath("introductionWoman.png")} alt="" />
      </div>
    </div>
  );
}
export interface ProjectType {
  id: number;
  title: string;
  image: string;
  type: string;
  result: string;
  solution: string;
  purpose: string;
}
export interface ProjectsDataType {
  designs: ProjectType[];
  bots: ProjectType[];
  stickers: ProjectType[];
}
export function Home() {
  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(
    null
  );
  const [showOrder, setShowOrder] = useState(false);
  const [projectsData, setProjectsData] = useState<ProjectsDataType>();

  useEffect(() => {
    if (!projectsData) {
      const resizeImages = (projects: any) => {
        return projects.map((p: any) => {
          let resizedImage: string = p.image_url.full;

          if (isTablet) {
            resizedImage = p.image_url.md;
          }
          if (isMobile) {
            resizedImage = p.image_url.xs;
          }
          return { ...p, image: resizedImage };
        });
      };
      fetch("/api/projects/")
        .then((resp) => resp.json())
        .then((data) => {
          const designs = resizeImages(data.design) || [];
          const bots = resizeImages(data.bot) || [];
          const stickers = resizeImages(data.sticker) || [];

          while (designs.length < 3) {
            designs.push({
              id: 0,
              image: "",
              result: "",
              solution: "",
              purpose: "",
            });
          }
          while (bots.length < 3) {
            bots.push({
              id: 0,
              image: "",
              result: "",
              solution: "",
              purpose: "",
            });
          }
          setProjectsData({ designs: designs, bots: bots, stickers: stickers });
        });
    }
  }, []);

  useEffect(() => {
    const classes = ["first", "second", "third"];

    document.querySelectorAll(".case-window").forEach((el, index) => {
      el.classList.remove(...classes);
      el.classList.add(classes[index % 3]);
    });

    const interval = setInterval(() => {
      if (selectedProject) {
        document.body.style.overflowY = "hidden";
      }
    }, 300);
    if (!selectedProject) {
      document.body.style.overflowY = "scroll";
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedProject]);

  useEffect(() => {
    const handleClose = (e: any) => {
      if (
        e.target.classList.contains("case-window-dark-bg") ||
        e.target.classList.contains("arrow-box") ||
        e.target.classList.contains("case-close-btn") ||
        e.target.closest(".case-close-btn")
      ) {
        setSelectedProject(null);
      }
    };
    document.body.addEventListener("mousedown", handleClose);
    return () => {
      document.body.removeEventListener("mousedown", handleClose);
    };
  }, [projectsData]);

  const handleMove = (to: string) => {
    const parent = document.querySelector(".open.full-page-cases");
    if (parent) {
      const items = {
        first: parent.querySelector(".first"),
        second: parent.querySelector(".second"),
        third: parent.querySelector(".third"),
      };
      if (to === "right") {
        moveForward(items);
      } else if (to === "left") {
        moveBack(items);
      }
    }
  };

  const orderBySelected = (data: ProjectType[]): ProjectType[] => {
    if (!selectedProject?.id || data.length !== 3) {
      return data;
    }
    const selectedIndex = data.findIndex(
      (project) => project.id === selectedProject.id
    );
    if (selectedIndex === -1) {
      return data;
    }
    const sortedData = [...data];
    const selectedProjectItem = sortedData.splice(selectedIndex, 1)[0];
    const middleIndex = Math.floor(sortedData.length / 2);
    sortedData.splice(middleIndex, 0, selectedProjectItem);
    return sortedData;
  };

  const renderProjectComponent = (project: ProjectType, index: number) => {
    const cssClass = index === 0 ? "first" : "third";

    const commonProps = {
      project,
      className: selectedProject?.id === project.id ? "second" : cssClass,
    };

    switch (project.type) {
      case "design":
        return <CaseSites {...commonProps} />;
      case "bot":
        return <CaseBot {...commonProps} />;
      case "sticker":
        return <CaseSticker {...commonProps} />;
      default:
        return null;
    }
  };

  if (!projectsData) {
    return (
      <div className="Loading">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <SaveVisit>
        {selectedProject
          ? [
              projectsData.designs,
              projectsData.bots,
              projectsData.stickers,
            ].map((data) => (
              <div
                className={`${
                  selectedProject?.type === data[0]?.type ? "open" : ""
                } full-page-cases`}
              >
                <CaseBase
                  selectedProdject={selectedProject}
                  handleMove={handleMove}
                >
                  {orderBySelected(data).map((project, index) =>
                    renderProjectComponent(project, index)
                  )}
                </CaseBase>
              </div>
            ))
          : null}
        <div className="home">
          <Header setShowOrder={setShowOrder} />
          <Introduction showOrder={showOrder} setShowOrder={setShowOrder} />
          <Services />
          {window.innerWidth > 1200 ? (
            <Cases
              projectsData={projectsData}
              setSelectedProject={setSelectedProject}
            />
          ) : (
            <MobileCases
              projectsData={projectsData}
              setSelectedProject={setSelectedProject}
            />
          )}
          <Contact />
        </div>
      </SaveVisit>
    </>
  );
}
