import React from "react";
import { ProjectType, mediaPath } from "../home/Home";
import "./caseSticker.css";

export function CaseSticker({
  className,
  project,
}: {
  className: string;
  project: ProjectType;
}) {
  return (
    <div className={`${className} caseSticker case-window`}>
      <div className="container">
        <div className="title">
          <span className="pink-text">КЕЙС</span>
          <p>{project.title}</p>
        </div>
        <div className="logo">
          <img src={mediaPath("logo.png")} alt="" />
        </div>
      </div>
      <div className="main">
        <div className="box">
          <div className="line"></div>
          <div className="mini-title">Цель</div>
          <div className="description">{project.purpose}</div>
        </div>
        <div className="box">
          <div className="line"></div>
          <div className="mini-title">Решение</div>
          <div className="description">{project.solution}</div>
        </div>
        <div className="box">
          <div className="line"></div>
          <div className="mini-title">Итог</div>
          <div className="description">{project.result}</div>
        </div>
      </div>
      <div className="sticker-image">
        <img src={project.image} alt="" />
        <img src={mediaPath("bgSticker.png")} alt="" />
      </div>
    </div>
  );
}
