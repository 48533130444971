import React, { ReactNode } from "react";
import { ProjectType } from "../home/Home";

export function CaseBase({
  children,
  handleMove,
  selectedProdject,
}: {
  children: ReactNode;
  handleMove: Function;
  selectedProdject: ProjectType | null;
}) {
  return (
    <div className="case-window-parent">
      {selectedProdject && (
        <div className="case-close-btn">
          <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.94238 7.83466L9.57384 11.4864C10.102 12.0176 10.8944 12.0176 11.4226 11.4864C11.6867 11.2872 11.8848 10.9552 11.8848 10.6233C11.8848 10.2913 11.7527 9.95931 11.4886 9.69373L7.79112 5.97559L11.4226 2.32384C11.9508 1.79268 11.9508 0.929536 11.4226 0.464768C11.2245 0.199186 10.8944 -4.32917e-08 10.4982 -6.06084e-08C10.102 -7.7925e-08 9.83794 0.132791 9.57384 0.398372L5.94238 4.05012L2.31091 0.398372C2.04681 0.199186 1.71668 -4.44461e-07 1.32052 -4.61778e-07C0.990384 -4.76209e-07 0.660251 0.13279 0.396145 0.398372C0.132039 0.663954 -1.43486e-05 0.995931 -1.43632e-05 1.32791C-1.43777e-05 1.65989 0.132039 1.99186 0.396145 2.25744L4.02761 5.90919L0.396144 9.56094C-0.132068 10.0921 -0.132068 10.9552 0.396144 11.42C0.924357 11.9512 1.7827 11.9512 2.24489 11.42L5.94238 7.83466Z" />
          </svg>
        </div>
      )}

      <div className="case-window-dark-bg"></div>
      <div className="arrow-box arrow-l">
        <div onClick={() => handleMove("left")} className="arrow-click-zone">
          <svg
            viewBox="0 0 42 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_44_320)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.3767 7.73248C35.3752 8.70879 35.3752 10.2917 34.3767 11.268L13.1733 32.0002L34.3767 52.7325C35.3752 53.7088 35.3752 55.2917 34.3767 56.268C33.3782 57.2443 31.7593 57.2443 30.7608 56.268L7.74949 33.768C6.75099 32.7917 6.75099 31.2088 7.74949 30.2325L30.7608 7.73248C31.7593 6.75617 33.3782 6.75617 34.3767 7.73248Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.3767 7.73248C35.3752 8.70879 35.3752 10.2917 34.3767 11.268L13.1733 32.0002L34.3767 52.7325C35.3752 53.7088 35.3752 55.2917 34.3767 56.268C33.3782 57.2443 31.7593 57.2443 30.7608 56.268L7.74949 33.768C6.75099 32.7917 6.75099 31.2088 7.74949 30.2325L30.7608 7.73248C31.7593 6.75617 33.3782 6.75617 34.3767 7.73248Z"
                stroke="#C00EFF"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_44_320"
                x="0.85061"
                y="0.850244"
                width="40.425"
                height="62.3"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="2.95" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.754517 0 0 0 0 0.0558334 0 0 0 0 1 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_44_320"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_44_320"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {children}
      <div className="arrow-box arrow-r">
        <div onClick={() => handleMove("right")} className="arrow-click-zone">
          <svg
            viewBox="0 0 42 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_44_321)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.87388 56.2678C6.87538 55.2915 6.87538 53.7085 7.87388 52.7322L29.0773 32L7.87388 11.2678C6.87538 10.2915 6.87538 8.70855 7.87388 7.73223C8.87238 6.75592 10.4913 6.75592 11.4898 7.73223L34.5011 30.2322C35.4996 31.2085 35.4996 32.7915 34.5011 33.7678L11.4898 56.2678C10.4913 57.2441 8.87238 57.2441 7.87388 56.2678Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.87388 56.2678C6.87538 55.2915 6.87538 53.7085 7.87388 52.7322L29.0773 32L7.87388 11.2678C6.87538 10.2915 6.87538 8.70855 7.87388 7.73223C8.87238 6.75592 10.4913 6.75592 11.4898 7.73223L34.5011 30.2322C35.4996 31.2085 35.4996 32.7915 34.5011 33.7678L11.4898 56.2678C10.4913 57.2441 8.87238 57.2441 7.87388 56.2678Z"
                stroke="#C00EFF"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_44_321"
                x="0.975"
                y="0.85"
                width="40.425"
                height="62.3"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="2.95" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.754517 0 0 0 0 0.0558334 0 0 0 0 1 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_44_321"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_44_321"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}
