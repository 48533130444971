import { ReactNode, useEffect, useRef } from "react";

export function AppearanceAnimated({
  className,
  children,
}: {
  className: string;
  children: ReactNode;
  startX?: number | undefined;
  startY?: number | undefined;
}) {
  const blockRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    function handleAnimation(target: Element) {
      target.classList.add("into-view-animate");
    }

    function handleIntersection(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          handleAnimation(entry.target);
          observer.unobserve(entry.target);
        }
      });
    }

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (blockRef.current) {
      observer.observe(blockRef.current);
    }

    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
    };
  }, []);

  return (
    <div className={className + " appear-animate"} ref={blockRef}>
      {children}
    </div>
  );
}
